import React from "react";
import Layout from "../../components/global/Layout/Layout";
import Operations from "../../components/Operations/Operations";
import { PageProps } from "gatsby";
import { IResourceFields } from "../../@types/IResources";

interface IPageContext {
  data: {
    [key: string]: IResourceFields[];
  };
  pageName: string;
  title: string;
}

const Resources = (props: PageProps<null, IPageContext>) => (
  <Layout>
    <Operations resources={props.pageContext} />
  </Layout>
);

export default Resources;
